let Notice = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    /**
* 获取所有消息
* @param {any} success
* @param {any} error
*/
  this.GetNotice = function (success, error) {
      var url = ServiceBaseAddress + '/api/Notice/GetNotice';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }

            }, error);
    }
}


export { Notice }
